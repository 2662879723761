<template>
  <div>

    <!-- Role Info: Input Fields -->
    <b-form>
      <b-row>
          
        <!-- Field: Role Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="roleData.name"
            /> 
            <!-- <p class= "firstletter">{{roleData.name}}</p> -->
          </b-form-group>
        </b-col>

        <!-- Field: Description -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="roleData.description"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Add Module -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Add new module"
            label-for="newmodules"
          >
            <b-form-select
              input-id="newmodules"
              v-model="newModule"
              :options="modules"
              value-field="_id"
              text-field="name"
            
              />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
         <h4>Modules - Permission</h4>
          <tr
            v-for="item in roleData.modules"
            :key="item._id"
          >
            <td>
              <div class="d-none d-lg-flex">
                <b-row class="flex-grow-1 px-1">
                  <b-col
                    cols="12"
                    lg="5"
                  >
                    <b-form>
                      {{ moduleName(item.module) }}
                    </b-form>
                  </b-col>  
                  <b-col
                    cols="12"
                    lg="5"
                  >
                    <b-form-checkbox
                      v-model="item.create"
                      plain
                    >
                      Create
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="item.update"
                      plain
                    >
                      Update
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="item.view"
                      plain
                    >
                      View
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="item.delete"
                      plain
                    >
                      Delete
                    </b-form-checkbox>
                  </b-col> 
                  <b-col
                    cols="12"
                    lg="2"
                  > 
                    <b-button
                      variant="outline-warning"
                      @click.prevent="deleteModules(item)"
                      >
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <br>
              <br>
            </td>
            
        </tr>
      
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <br>
    <br>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateRole(roleData._id)"
    >
      Save Changes
    </b-button>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moduleStoreModule from '../../../pages/modules/moduleStoreModule'
import useRolesList from '../roles-list/useRolesList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  props: {
    roleData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const MODULE_APP_STORE_MODULE_NAME = 'app-module'
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.unregisterModule(MODULE_APP_STORE_MODULE_NAME)
    })
    
    const modules = ref([])
    store.dispatch('app-module/fetchModules')
      .then(response => { 
        modules.value = response.data.modules})

    return {
      modules,
    }

  },
  data() {
    return {
      newModule: ''
    }
  },
  methods: {
    updateRole(Id) {
    
      if (this.newModule != '') {
        this.addModule()
      }
            
      store.dispatch('app-role/updateRole', { id: Id, roleData: this.roleData})
        .then(() => {
          this.$router.go(0)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Role updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },

    addModule() {
      var module = this.roleData.modules.find(item => item.module == this.newModule)
      if (!module) {
        var newModule = {module:this.newModule, create: 'false', update: 'false', view: 'false', delete: 'false'} 
        this.roleData.modules.push(newModule)
        this.newModule = ''
      }else{
        this.newModule = ''
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Module already exists',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    deleteModules(moduleId) {
      var i = this.roleData.modules.indexOf( moduleId )
     
      if ( i !== -1 ) {
         this.roleData.modules.splice( i, 1 );
      } 
    },

    moduleName(id) {
      const found = this.modules.find(element => element._id == id)
      if (found) {
        return found.name
      }
    }, 
    
    capitalize(word) {
      return word[0].toUpperCase() + word.slice(1);
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.firstletter::first-letter{
  color: #efb810 !important;
  font-size:2em;
	font-family:serif;
  text-transform: capitalize;
}
</style>
